import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`From Side Project to Production App`}</h1>
    <p>{`Over 4 years ago I started building an RSS reader called `}<a parentName="p" {...{
        "href": "https://feedwise.net"
      }}>{`FeedWise`}</a>{`.`}</p>
    <p>{`I've decided to evolve this side project into a Production-quality application.`}</p>
    <h2><HashLink id="why-feedwise" to="/hobby-to-prod#why-feedwise" mdxType="HashLink">{`Why does FeedWise exist?`}</HashLink></h2>
    <p>{`Initially, there were two main reasons to build this app.`}</p>
    <ol>
      <li parentName="ol">{`I wanted full control of a portal into the corners of the internet that I'm interested in.`}</li>
      <li parentName="ol">{`I wanted to work on a side project that's practical for personal use `}<em parentName="li">{`and`}</em>{` I could use to experiment with technology.`}</li>
    </ol>
    <p>{`One classic example of using a side project for learning is when devs build a To-do MVC app to learn new tech stacks. `}<a parentName="p" {...{
        "href": "https://feedwise.net/"
      }}>{`FeedWise`}</a>{` was essentially serving the same purpose but was a more useful product for me.`}</p>
    <p>{`Over time I've rebuilt this application using a whole bunch of different tech stacks. Most times I would try out new programming languages, infrastructure, etc. to help learn the tech for my day job. Other times I would rebuild the application using technology purely because it interested me.`}</p>
    <p>{`As the years passed, the `}<em parentName="p">{`why`}</em>{` of this project has matured.`}</p>
    <p>{`Since the inception of the project I was not on Facebook, Instagram, or TikTok but I would still spend a lot of time on YouTube, Reddit, and occasionally X. Long story short, I found that the feed algorithms were doing a great job of capturing my attention and time.`}</p>
    <p>{`I realized two things:`}</p>
    <ol>
      <li parentName="ol">{`I was becoming addicted to these platforms.`}</li>
      <li parentName="ol">{`There was once a "before time" where each day I chose where to focus and for how long.`}</li>
    </ol>
    <p>{`Nowadays I see the value of my attention. `}<a parentName="p" {...{
        "href": "https://feedwise.net"
      }}>{`FeedWise`}</a>{` helps me reclaim my attention from the big tech algorithms.`}</p>
    <h2><HashLink id="going-for-prod" to="/hobby-to-prod#going-for-prod" mdxType="HashLink">{`Deciding to build a Production app`}</HashLink></h2>
    <p>{`One day I realized that using this app daily has had such a positive benefit on my life. I asked myself, "What if one more person could reclaim their attention?"`}</p>
    <p>{`The prospect of being able to help just one more person inspired me to slowly start converting my untested, hobby code into a robust production system on nights and weekends.`}</p>
    <p>{`Given my professional work experience, I know how to write production code. However, the code I've historically written for hobby projects has always been extremely naive in comparison. When I'm slinging code for a side project I optimize for time, don't write tests, and just get something working. If it's buggy but there are workarounds then I simply live with the bugs because I'm the only user.`}</p>
    <p>{`In hindsight, I realize I would've saved myself a ton of time by being a bit more thorough when building the hobby project. It took longer than expected to essentially port my spaghetti code into solid production code.`}</p>
    <h2><HashLink id="next-steps" to="/hobby-to-prod#next-steps" mdxType="HashLink">{`Next steps`}</HashLink></h2>
    <p>{`Now that the app has launched, I will maintain the product and polish it indefinitely. Unless RSS goes away, I don't foresee the need to use any other application to browse the web for as long as I live. It's a pretty cool feeling.`}</p>
    <p>{`I've learned a ton and I'm still learning each day. My plan is to write down my learnings from this journey, publish them online, and deliver tech talks at local meetups.`}</p>
    <p>{`My hope is that my journey inspires others to embark on similar adventures.`}</p>
    <p>{`The product itself isn't the only thing that has improved my life. There's something special about working on a project that truly interests you. I've found the learnings to be much deeper than at my day job. I've found my passion for building is at an all-time high. Last but certainly not least, it's awesome to take pride in building something and bringing your vision to life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      